import request from '@/utils/request'

export function getPersonalSetting() {
    return request({
        url: '/api/personal_setting',
        method: 'get',
    })
}
export function update_personal_settings(data) {
  // console.log(data);
    return request({
        url: '/api/personal_setting',
        method: 'post',
        data
    })
}