import request from '@/utils/request'

export function getluckywheel(query) {
    console.log("getluckywheel",typeof(query))
    // console.log("get_question", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/lucky_wheel/' + query,
            method: 'get',
        })
    }
    else{
        return request({
            url: '/api/lucky_wheel/' + query,
            method: 'get',
        })
    }
}
export function insert_update_lucky_wheel(data) {
  // console.log(data);
    return request({
        url: '/api/lucky_wheel',
        method: 'post',
        data
    })
}