import request from '@/utils/request'

export function getPartner(query) {
    console.log("get_partner",typeof(query))
    console.log("get_partner",query)
    if (typeof (query) === 'number') {
        return request({
            url: '/api/partner/' + query,
            method: 'get'
        })
    } else {
        return request({
            url: '/api/partner/' + query,
            method: 'get'
        })
    }
}
export function getPartnerCategory(page) {
    return request({
        url: '/api/partner_category/' + page,
        method: 'get',
    })
}
export function insert_update_partner(data) {
  // console.log(data);
    return request({
        url: '/api/partner',
        method: 'post',
        data
    })
}
export function insert_update_partner_category(data) {
    // console.log(data);
    return request({
        url: '/api/partner_category',
        method: 'post',
        data
    })
}

export function search_partner_lat_lng(data) {
    console.log(data);
    return request({
        url: 'api/switch_partner_lat_lon',
        method: 'post',
        data
    })
}

export function getPartnerConfig(query){
    console.log('getpartnerconfig')
    return request({
        url: 'api/config_tab_name',
        method: 'get',
        query
    })
}