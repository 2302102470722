import { getMainLinePackage, insert_update_main_line_package, getSubLinePackage, insert_update_sub_line_package } from '@/api/line_package_manager'
import { Message } from 'element-ui'

const state = {
    mainpackagelist:[],
    subpackagelist:[]
}

const mutations = {
    SET_MAIN_PACKAGE: (state, mainpackagelist) => {
        state.mainpackagelist = mainpackagelist
    },
    SET_SUB_PACKAGE: (state, subpackagelist) => {
        state.subpackagelist = subpackagelist
    },
}

const actions = {
    // 得知所有貼圖主分類
    getMainLinePackage({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getMainLinePackage(query).then(response => {
                commit('SET_MAIN_PACKAGE', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有貼圖主分類
    insert_update_main_line_package({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_main_line_package(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知所有貼圖子分類
    getSubLinePackage({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getSubLinePackage(query).then(response => {
                commit('SET_SUB_PACKAGE', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有貼圖子分類
    insert_update_sub_line_package({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_sub_line_package(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
