import request from '@/utils/request'


//得知單位管理
export function getUnitList(query) {
  console.log('get_unit_list', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/index_unit_record/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/index_unit_record/' + query,
      method: 'get'
    })
  }
}

//新增、修改單位管理
export function postUnitList(data) {
  console.log('post_unit_list', typeof (data))
    return request({
      url: '/api/index_unit_record',
      method: 'post',
      data
    })
}
