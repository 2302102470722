import request from '@/utils/request'


//得知會計管理（預約）
export function getAppointReceptionList(query) {
  console.log('get_reception_record', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/reception_record/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/reception_record/' + query,
      method: 'get'
    })
  }
}

//新增、修改會計管理（預約）
export function postAppointReceptionList(data) {
  console.log('insert_update_reception_record_list', typeof (data))
    return request({
      url: '/api/insert_update_reception_record',
      method: 'post',
      data
    })
}

//得知會計管理(商品)
export function getAppointReceptionShopList(query) {
  console.log('get_reception_shop_record', typeof (query))
  // console.log("get_admin", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/reception_shop_record/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/reception_shop_record/' + query,
      method: 'get'
    })
  }
}

//新增、修改會計管理（商品）
export function postAppointReceptionShopList(data) {
  console.log('insert_update_reception_shop_record_list', typeof (data))
    return request({
      url: '/api/insert_update_reception_shop_record',
      method: 'post',
      data
    })
}

//匯出會計管理(預約)
export function exportAppointReceptionReserveShopList(query) {
  console.log('export_reception_reserve_shop_record_list', typeof (query))
    return request({
      url: '/api/export_reception_reserve_record/'+query,
      method: 'get'
    })
}

//匯出會計管理(預約)
export function exportAppointReceptionShopList(query) {
  console.log('export_reception_shop_record_list', typeof (query))
    return request({
      url: '/api/export_reception_record/'+query,
      method: 'get'
    })
}
