import { getInnerConfig, InsertUpdateConfigName,confirmOuterConfig } from '@/api/develop_manager'
import { Message } from 'element-ui'

const state = {
    innerconfig: [],
}

const mutations = {
    SET_INNER_CONFIG: (state, innerconfig) => {
        state.innerconfig = innerconfig
    }
}

const actions = {
    // 得知設定內層資料
   getInnerConfig({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
           getInnerConfig(query).then(response => {
               commit('SET_INNER_CONFIG', response.data)
                resolve(response.data)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有設定黨內層
    insert_update_config_name({ commit }, data) {
        return new Promise((resolve, reject) => {
            InsertUpdateConfigName(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有設定黨內層
    insert_update_outer_config_name({ commit }, data) {
        return new Promise((resolve, reject) => {
            confirmOuterConfig(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
