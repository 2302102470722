import { getShop, getShopCategory, insert_update_shop, insert_update_shop_category, insert_update_shopstore, getOrder, getAllMember, insert_update_order } from '@/api/shop_manager'
import { Message } from 'element-ui'

const state = {
   shoplist:[],
   shopcategorylist: [],
   orderlist:[],
   allmemberlist:[],
   check_chat_auto_update:false
}

const mutations = {
    SET_SHOP: (state, shoplist) => {
        state.shoplist = shoplist
    },
    SET_SHOP_CATEGORY: (state, shopcategorylist) => {
        state.shopcategorylist = shopcategorylist
    },
    SET_ORDER:(state,orderlist)=>{
        state.orderlist = orderlist
    },
    SET_ALL_MEMBER: (state, allmemberlist) => {
        state.allmemberlist = allmemberlist
    },
    SET_CHECK_CHAT_AUTO_UPDATE: (state, getChatUpdate)=>{
        state.check_chat_auto_update = getChatUpdate
    }
}

const actions = {
    // 得知所有商品管理
    getShop({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getShop(query).then(response => {
                commit('SET_SHOP', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知所有商品管理分類
    getShopCategory({ commit }, page) {
        return new Promise((resolve, reject) => {
            getShopCategory(page).then(response => {
                // console.log("test", response)
                commit('SET_SHOP_CATEGORY', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有商品管理
    insert_update_shop({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_shop(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有商品管理分類
    insert_update_shop_category({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_shop_category(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有商店資訊
    insert_update_shopstore({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_shopstore(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },

    // 得知所有訂單管理
    getOrder({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getOrder(query).then(response => {
                commit('SET_ORDER', response.data)
                resolve(response.data)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有訂單管理
    insert_update_order({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_order(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知所有會員
    getAllMember({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getAllMember(query).then(response => {
                commit('SET_ALL_MEMBER', response.data)
                resolve(response.data)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    //即時更新聊天狀況
    getChatUpdate({ commit },data){
        commit('SET_CHECK_CHAT_AUTO_UPDATE',data)
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
