<template>
  <div :class="className" :style="{height:height,width:width}" />
</template>

<script>
import { get_Appoint_Dashboard_2 } from '@/api/reservesystemdashboard'
import echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'

export default {
  mixins: [resize],
  props: {
    partner:{
      type: String,
      require: true
    },
    select_start_date:{
      type: String,
      require: true
    },
    select_end_date:{
      type: String,
      require: true
    },
    search_period_date:{
      type: String,
      require: true
    },
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    select_class_range:{
      type: Number,
      default: 0,
      require: true
    },
  },
    watch:{
    select_class_range:{
        deep: true,
        handler: function(val, oldVal) {
          //課程
          if(val==0){
            this.$nextTick(
              this.initChart_type()
            )
          }
          //治療師
          if(val==1){
            this.$nextTick(
              this.initChart_type()
            )
          }
        },
    },
    search_period_date:{
      handler: async function(val, oldVal) {
            await this.get_Appoint_Dashboard_2()
            await this.initChart_type()
        },
    },
    immediate:true,
  },
  async mounted() {
    console.log('mounted PieChart start ')
    console.log(this.partner)
    console.log(this.select_class_range)
    console.log(this.select_start_date)
    console.log(this.select_end_date)
    console.log(this.search_period_date)
    await this.get_Appoint_Dashboard_2()
    await this.initChart_type()
    console.log('mounted PieChart end')
  },
  data() {
    return {
      chart: null,
      //熱銷程度排行（課程）
      get_popular_type_0_list:[],
      //熱銷程度排行實際對應資料（課程）
      get_popular_data_type_0_list:[],
      //熱銷程度排行（治療師）
      get_popular_type_1_list:[],
      //熱銷程度排行實際對應資料（治療師）
      get_popular_data_type_1_list:[],
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    async get_Appoint_Dashboard_2(){
      var payload = ''
      payload ='?partner_id='+this.partner+'&search_type='+this.select_class_range+
      '&search_start_date='+this.select_start_date+'&search_end_date='+this.select_end_date
      await get_Appoint_Dashboard_2(payload).then(async (resp)=>{
        //熱銷程度排行（課程）
        this.get_popular_type_0_list = resp.get_popular_type_0_list
        //熱銷程度排行實際對應資料（課程）
        this.get_popular_data_type_0_list = resp.get_popular_data_type_0_list
        //熱銷程度排行（治療師）
        this.get_popular_type_1_list= resp.get_popular_type_1_list
        //熱銷程度排行實際對應資料（治療師）
        this.get_popular_data_type_1_list = resp.get_popular_data_type_1_list
      })
    },
    initChart_type() {
    console.log('initChart_type PieChart start ')
    console.log(this.partner)
    console.log(this.select_class_range)
    console.log(this.select_start_date)
    console.log(this.select_end_date)
    console.log(this.search_period_date)
    console.log('initChart_type PieChart end')
    this.chart = null
      this.chart = echarts.init(this.$el, 'macarons')
      this.chart.setOption({
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          left: 'center',
          bottom: '10',
          data: this.select_class_range==0?this.get_popular_type_0_list:this.get_popular_type_1_list
        },
        series: [
          {
            name: '熱銷程度',
            type: 'pie',
            roseType: 'radius',
            radius: [15, 95],
            center: ['50%', '40%'],
            data: this.select_class_range==0?this.get_popular_data_type_0_list:this.get_popular_data_type_1_list,
            animationEasing: 'cubicInOut',
            animationDuration: 2900
          }
        ]
      })
    }
  }
}
</script>
