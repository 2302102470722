import request from '@/utils/request'

export function getAnnounce(query) {
    console.log("get_announce",typeof(query))
    // console.log("get_admin", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/admin_announce_record/' + query,
            method: 'get',
        })
    }
}
export function insert_update_announce(data) {
    // console.log(data);
    return request({
        url: '/api/admin_announce_record',
        method: 'post',
        data
    })
}