<template>
    <div style="margin:10px 0;">
        <el-dialog :visible="dialogVisible" title="查看預約人數"
            :before-close="closeAddAppointReservePerson" center width="80%" append-to-body>
            <el-row :gutter="20">
                <!-- {{appointschedule}} -->
                <el-form status-icon :model="appointschedule" label-position="right">
                    <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                        <el-form-item label="探訪時段名稱">
                            <el-tag :type="appointschedule.reserve_system_shop_basic_schedule_status==0?'':'danger'" style="margin:10px 0; font-size:20px;" v-show="appointschedule.reserve_system_shop_basic_schedule_class==0">
                            ({{appointschedule.reserve_system_shop_basic_schedule_class|reserve_system_shop_basic_schedule_class}}) {{appointschedule.reserve_system_shop_basic_schedule_teacher}} {{appointschedule.reserve_system_shop_basic_schedule_class_name}}
                            </el-tag>
                            <el-tag :type="appointschedule.reserve_system_shop_basic_schedule_status==0?'':'danger'" style="margin:10px 0; font-size:20px;" v-show="appointschedule.reserve_system_shop_basic_schedule_class==1">
                            ({{appointschedule.reserve_system_shop_basic_schedule_class|reserve_system_shop_basic_schedule_class}}) {{appointschedule.reserve_system_shop_basic_schedule_teacher}}
                            </el-tag>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                        <el-form-item label="報名時段">
                             <el-tag :type="appointschedule.reserve_system_shop_basic_schedule_status==0?'':'danger'" style="margin:10px 0; font-size:20px;">
                                {{appointschedule.reserve_system_shop_basic_schedule_start_date}} {{appointschedule.reserve_system_shop_basic_schedule_start_time}} ~
                                {{appointschedule.reserve_system_shop_basic_schedule_end_date}} {{appointschedule.reserve_system_shop_basic_schedule_end_time}}
                             </el-tag>
                             <el-tag type="danger" style="margin:10px; font-size:20px;">( {{appointschedule.reserve_system_shop_basic_schedule_last_reserve_date}} {{appointschedule.reserve_system_shop_basic_schedule_last_reserve_time}} )</el-tag> 
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                        <el-form-item label="報名人數">
                            <el-tag style="font-size:20px; text-align:center;" 
                            :type="appointschedule.reserve_system_shop_basic_schedule_status==0?'':'danger'"
                            v-show="appointschedule.reserve_system_shop_basic_schedule_limit_switch==0"
                            >
                                {{appointschedule.reserve_system_shop_basic_schedule_practice_people}} 人
                            </el-tag>
                            <el-tag style="font-size:20px; text-align:center;" 
                            :type="appointschedule.reserve_system_shop_basic_schedule_limit_people>appointschedule.reserve_system_shop_basic_schedule_practice_people?'':'danger'"
                            v-show="appointschedule.reserve_system_shop_basic_schedule_limit_switch==1">
                                {{appointschedule.reserve_system_shop_basic_schedule_practice_people}} / {{appointschedule.reserve_system_shop_basic_schedule_limit_people}} 人
                            </el-tag><br/>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="12" :lg="12" :xl="12">
                        <el-form-item label="排程狀態">
                            <el-tag :type="appointschedule.reserve_system_shop_basic_schedule_status==0?'':'danger'" style="margin:10px 0; font-size:20px;">
                                {{appointschedule.reserve_system_shop_basic_schedule_status|reserve_system_shop_basic_schedule_status}}
                            </el-tag>
                        </el-form-item>
                    </el-col>
                </el-form>
                <el-table v-loading="orderlistLoading" 
                :data="orderList.list"
                style="width: 100%;" border fit highlight-current-row>
                <el-table-column align="center" label="床號或身分證字號">
                    <template slot-scope="scope">
                        {{ scope.row.reserve_system_health_reserve_bed_identify }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="長輩姓名">
                    <template slot-scope="scope">
                        {{ scope.row.reserve_system_health_reserve_patient }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="訪視者資訊">
                    <template slot-scope="scope">
                    <div v-for="item in scope.row.relation_list">
                        <hr/>
                        {{ item.reserve_system_health_reserve_name }}<br/>
                        {{ item.reserve_system_health_reserve_relationshop_name }}<br/>
                        {{ item.reserve_system_health_reserve_relationshop_phone }}
                        <hr/>
                    </div>
                    </template>
                </el-table-column>
                <el-table-column width="250" align="center" label="情況/備註">
                    <template slot-scope="scope">
                        <div v-if="scope.row.reserve_system_health_reserve_note!=null">
                            <span style="margin:10px 0;color:red;">
                            {{scope.row.reserve_system_health_reserve_note}}
                            </span>
                        </div>
                        <div v-else>
                            <span style="margin:10px 0;">
                            無額外情況/備註
                            </span>
                            <!-- <el-tag style="margin:10px 0;">
                                無額外需求
                            </el-tag> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="預約狀態">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.reserve_system_reserve_status==0 ?'danger':''" style="margin:10px 0;">
                            {{ scope.row.reserve_system_reserve_status|	reserve_system_reserve_status }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="預約時間">
                    <template slot-scope="scope">
                        {{ scope.row.reserve_system_health_reserve_datetime }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="報到狀態">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.reserve_system_join_status==0 ?'danger':''" style="margin:10px 0;">
                            {{ scope.row.reserve_system_join_status|reserve_system_join_status }}
                        </el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="報到時間">
                    <template slot-scope="scope">
                        {{ scope.row.reserve_system_health_join_datetime }}
                    </template>
                </el-table-column>
                </el-table>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
import { getAppointReserveList } from '@/api/reservationsystemappointorder'
export default {
    name: 'ShowAppointScheduleDetail',
    props: {
        dialogType: {
            type: String,
            require: true
        },
        dialogVisible: {
            type: Boolean,
            require: true
        },
        appointschedule: {
            type: String,
            require: true
        },
        partner: {
            type: String
            //   default: () => {
            //     return {
            //       name: '',
            //       email: '',
            //       avatar: '',
            //       role: ''
            //     }
            //   }
        }
    },
    data() {
        return {
            orderList:[],
            orderlistLoading:false
        }
    },
    filters: {
        reserve_system_shop_basic_schedule_class(status){
        const reserve_system_shop_basic_schedule_class = {
            0: '課程',
            1: '探訪'
        }
        return reserve_system_shop_basic_schedule_class[status]
        },
        reserve_system_reserve_status(status){
        const reserve_system_reserve_status = {
            0: '預約取消',
            1: '預約成功',
        }
        return reserve_system_reserve_status[status]
        },
        reserve_system_shop_basic_schedule_status(status) {
            const reserve_system_shop_basic_schedule_status = {
                0: '可報名',
                1: '不可報名',
                2: '額滿'
            }
            return reserve_system_shop_basic_schedule_status[status]
        },
        reserve_system_join_status(status){
        const reserve_system_join_status = {
            0: '未報到',
            1: '已報到',
        }
        return reserve_system_join_status[status]
        }
    },
  watch:{
        dialogType:{
        handler: function(val, oldVal) {
            //當為查看預約人數，帶入所有預約所有資訊
            if(val=='check'){
                var payload ='partner_id='+this.appointschedule.partner_id+'&reserve_system_shop_basic_schedule_id='+this.appointschedule.reserve_system_shop_basic_schedule_id
                this.getAllOrder('all',payload)
            }
        },
        immediate: true
        },
    },
    methods: {
        closeAddAppointReservePerson(){
        //更新資料進入外層的關閉選單
        this.dialogVisible = false
        this.dialogType = ''
        this.$emit('getAppoint_schedule_detail_dialogtype',this.dialogVisible,this.dialogType)
        },
        async getAllOrder(page, order) {
        var payload = ''
            payload += page + '?' + order
        this.orderlistLoading = true
        getAppointReserveList(payload).then(resp => {
            this.orderList = resp.data
            this.orderlistLoading = false
        })
        },
    },
}
</script>