import { getService, getServiceCategory, insert_update_service, insert_update_service_category,edit_delete_service_photo } from '@/api/reservationsystemappointservice'
import { Message } from 'element-ui'

const state = {
   servicelist:[],
   servicecategorylist: []
}

const mutations = {
    SET_SERVICE: (state, servicelist) => {
        state.servicelist = servicelist
    },
    SET_SERVICE_CATEGORY: (state, servicecategorylist) => {
        state.servicecategorylist = servicecategorylist
    }
}

const actions = {
    // 得知所有服務項目
    getService({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getService(query).then(response => {
                commit('SET_SERVICE', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知所有服務項目分類
    getServiceCategory({ commit }, page) {
        return new Promise((resolve, reject) => {
            getServiceCategory(page).then(response => {
                // console.log("test", response)
                commit('SET_SERVICE_CATEGORY', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有服務項目
    insert_update_service({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_service(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success == false ? 'error' : 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    //新增及修改所有服務項目分類
    insert_update_service_category({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_service_category(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    //修改、刪除服務項目圖片資料
    edit_delete_service_photo({ commit }, data) {
        return new Promise((resolve, reject) => {
            edit_delete_service_photo(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
