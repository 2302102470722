import { getluckywheel, insert_update_lucky_wheel } from '@/api/lucky_wheel_manager'
import { Message } from 'element-ui'

const state = {
   luckywheellist:[],
}

const mutations = {
    SET_LUCKY_WHEEL: (state, luckywheellist) => {
        state.luckywheellist = luckywheellist
    }
}

const actions = {
    // 得知所有最新消息
    getLuckyWheel({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getluckywheel(query).then(response => {
                commit('SET_LUCKY_WHEEL', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有最新消息
    insert_update_luckywheel({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_lucky_wheel(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
