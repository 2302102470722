<template>
  <div>
    <!--error code-->
    {{ a.a }}
    <!--error code-->
  </div>
</template>

<script>
export default {
  name: 'ErrorTestA'
}
</script>
