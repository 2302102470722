import { insert_update_announce } from '@/api/announce_manager'
import { Message } from 'element-ui'


const actions = {
    // 新增及修改所有公告管理
    insert_update_announce({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_announce(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success == false ? 'error' : 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    // state,
    // mutations,
    actions
}
