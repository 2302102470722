<template>
    <div>
    <el-dropdown trigger="click" class="github-corner" @command="handleclickannounce">
      <span class="el-dropdown-link">
        公告訊息<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="first" icon="el-icon-news">此次公告</el-dropdown-item>
        <el-dropdown-item command="second" icon="el-icon-news">歷史公告</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div v-if="today_announce_list.length!=0">
      <el-dialog
        :title="today_announce_list[0].announce_title"
        :visible.sync="dialogVisible"
        width="50%"
        center
        >
        <!-- :before-close="handleClose" -->
        <div class="announce_content">
          <span style="color:blue;white-space:pre-wrap;font-size: 16px;" v-html="today_announce_list[0].announce_content">
          </span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">確定</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog
      title="歷史公告"
      :visible.sync="dialogVisible_past"
      width="50%"
      center
      >
      <!-- :before-close="handleClose" -->
      <!-- @change="handleChange" -->
    <el-collapse accordion>
      <div v-for="(past_announce_list,index) in past_announce_list" :key="index">
      <el-collapse-item :title="past_announce_list.announce_title" :name="index" class="announce_content"> 
        <div style="color:blue;white-space:pre-wrap;font-size: 16px;">{{past_announce_list.announce_content}}</div>
      </el-collapse-item>
      </div>
    </el-collapse>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible_past = false">確定</el-button>
      </span>
    </el-dialog>
    </div>
</template>

<script>
import { getAnnounce } from '@/api/announce_manager'

export default {
  name: 'announce-list',
  data() {
    return {
      dialogVisible: false,
      dialogVisible_past:false,
      today_announce_list:[],
      past_announce_list:[],
    }
  },
  mounted(){
    //得知此次公告
    this.get_recent_announce()
    //得知過去公告
    this.get_past_announce()
  },
  methods: {
    //切換不同公告訊息
    handleclickannounce(command){
      if(command=="first"){
        this.dialogVisible = true
      }
      if(command=="second"){
        this.dialogVisible_past = true
      }
    },
    //得知此次公告
    get_recent_announce(){
      getAnnounce(1).then((resp)=>{
          if(resp.data.list!=[]){
            resp.data.list.forEach((element,index) => {
              if(index==0){
                this.today_announce_list.push({
                  "announce_title":element.announce_title,
                  "announce_content":element.announce_content
                })
              }
            });
          }
      })
    },
    //得知過去公告
    get_past_announce(){
      getAnnounce(1).then((resp)=>{
        // this.past_announce_list = resp.data.list
        if(resp.data.list!=[]){
          resp.data.list.forEach((element,index) => {
            if(index!=0){
              this.past_announce_list.push({
                "announce_title":element.announce_title,
                "announce_content":element.announce_content
              })
            }
          });
        }
      })
    }
  }
}
</script>
<style>
/* 公告訊息-此次公告，文字置中對齊 */
.announce_content {
    text-align: center !important;
    font-size: 16px;
}
</style>
<style lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  position: relative;

  .github-corner {
    position: absolute;
    top: 10px;
    border: 0;
    right: 10px;
    color:red;
  }

  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}

@media (max-width:1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
