import { insert_update_member_tag } from '@/api/member_tag_manager'
import { getGroupBuyMember,insertgroupmember,updategroupmember } from '@/api/groupbuy_manager'
import { Message } from 'element-ui'

const state = {
    // memberlist: [],
}

const mutations = {
    // SET_GROUP_BUY_MEMBER: (state, memberlist) => {
    //     state.memberlist = memberlist
    // },
}

const actions = {
    // 新增及修改所有最新消息
    insert_update_membertag({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_member_tag(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success == false ? 'error' : 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知所有團購會員
    // getMember({ commit }, query) {
    //     return new Promise((resolve, reject) => {
    //         getGroupBuyMember(query).then(response => {
    //             commit('SET_GROUP_BUY_MEMBER', response.data.list)
    //             resolve(response.data.list)
    //         }).catch(error => {
    //             console.log("error", error)
    //             reject(error)
    //         })
    //     })
    // },
    // 新增所有團購會員
    insert_groupbuy_member({ commit }, data) {
        return new Promise((resolve, reject) => {
            insertgroupmember(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success == false ? 'error' : 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 修改所有團購會員
    update_groupbuy_member({ commit }, data) {
        return new Promise((resolve, reject) => {
            updategroupmember(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success == false ? 'error' : 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
