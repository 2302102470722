import variables from '@/styles/element-variables.scss'
import defaultSettings from '@/settings'
import { getPersonalSetting, update_personal_settings } from '@/api/settings'
import { Message } from 'element-ui'

const { showSettings, tagsView, fixedHeader, sidebarLogo } = defaultSettings

const state = {
  theme: variables.theme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  },
  SET_INIT_SETTING: (state, all_settings) => {
    state.theme = all_settings[0].switch_theme
    state.showSettings = all_settings[0].switch_show_settings
    state.tagsView = all_settings[0].switch_tags_view
    state.fixedHeader = all_settings[0].switch_show_fixheader
    state.sidebarLogo = all_settings[0].switch_sidebar_logo
  }
}

const actions = {
  changeSetting({ commit }, data) {
    commit('CHANGE_SETTING', data)
  },
  generatesetting({ commit }){
    return new Promise((resolve, reject) => {
      getPersonalSetting().then(response => {
        commit('SET_INIT_SETTING', response.data.list)
        resolve(response.data.list)
      }).catch(error => {
        console.log("error", error)
        reject(error)
      })
    })
  }, 
  update_personal_settings({ commit }, data){
    // console.log("1229",data)
    return new Promise((resolve, reject) => {
      update_personal_settings(data).then(response => {
        Message({
          message: response.message,
          type: 'success',
          duration: 1500
        })
        resolve(response.data.list)
      }).catch(error => {
        console.log("error", error)
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

