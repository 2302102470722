import request from '@/utils/request'

export function getHealth(query) {
    console.log("get_health",typeof(query))
    // console.log("get_question", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/appoint_health_record/' + query,
            method: 'get',
        })
    }
    else{
        return request({
            url: '/api/appoint_health_record/' + query,
            method: 'get',
        })
    }
}
export function getHealthCategory(page) {
    return request({
        url: '/api/appoint_health_category_record/' + page,
        method: 'get',
    })
}
export function insert_update_health(data) {
  // console.log(data);
    return request({
        url: '/api/appoint_health_record',
        method: 'post',
        data
    })
}
export function insert_update_health_category(data) {
    // console.log(data);
    return request({
        url: '/api/appoint_health_category_record',
        method: 'post',
        data
    })
}

export function edit_delete_health_photo(data) {
    // console.log(data);
    return request({
        url: '/api/edit_delete_health_photo_record',
        method: 'post',
        data
    })
}

export function deleteHealthCategory(data){
    return request({
        url: '/api/delete_health_category_record',
        method: 'post',
        data
    })
}

export function postHealthCategoryOrderList(data){
    return request({
        url: '/api/appoint_health_category_order_record',
        method: 'post',
        data
    })
}
export function postHealthOrderList(data){
    return request({
        url: '/api/appoint_health_order_record',
        method: 'post',
        data
    })
}