import { getRouterPermissionFather, getRouterPermissionSon, insert_update_father_router, insert_update_son_router} from '@/api/router_manager'
import { Message } from 'element-ui'

const state = {
    routerfatherlist: [],
    routersonlist: []
}

const mutations = {
    SET_ROUTER_PERMISSION_FATHER: (state, routerfatherlist) => {
        state.routerfatherlist = routerfatherlist
    },
    SET_ROUTER_PERMISSION_SON: (state, routersonlist) => {
        state.routersonlist = routersonlist
    }
}

const actions = {
    // 得知父類別
    getRouterPermissionFather({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getRouterPermissionFather(query).then(response => {
                commit('SET_ROUTER_PERMISSION_FATHER', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知子類別
    getRouterPermissionSon({ commit }, page) {
        return new Promise((resolve, reject) => {
            getRouterPermissionSon(page).then(response => {
                // console.log("test", response)
                commit('SET_ROUTER_PERMISSION_SON', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有父層權限
    insert_update_father_router({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_father_router(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有子層權限
    insert_update_son_router({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_son_router(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
