import { getPartner, getPartnerCategory, insert_update_partner, insert_update_partner_category, getPartnerConfig } from '@/api/partner_manager'
import { Message } from 'element-ui'

const state = {
    partnerlist:[],
    partnercategorylist: [],
    partner_config:[]
}

const mutations = {
    SET_PARTNER: (state, partnerlist) => {
        state.partnerlist = partnerlist
    },
    SET_PARTNER_CATEGORY: (state, partnercategorylist) => {
        state.partnercategorylist = partnercategorylist
    },
    SET_PARTNER_CONFIG: (state, partner_config)=>{
        state.partner_config = partner_config
    }

}

const actions = {
    // 得知所有合作夥伴
    getPartner({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getPartner(query).then(response => {
                commit('SET_PARTNER', response.data)
                resolve(response.data)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知所有合作夥伴分類
    getPartnerCategory({ commit }, page) {
        return new Promise((resolve, reject) => {
            getPartnerCategory(page).then(response => {
                commit('SET_PARTNER_CATEGORY', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有合作夥伴
    insert_update_partner({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_partner(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有合作夥伴分類
    insert_update_partner_category({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_partner_category(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 取得合作夥伴設定檔
    getPartnerConfig({commit}){
        return new Promise((resolve, reject) => {
            getPartnerConfig().then(response =>{
                commit('SET_PARTNER_CONFIG',response.data)
                resolve(response)
            }).catch(error=>{
                console.log("getconfig error",error)
                reject(error)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
