import { getTodolist, insert_update_todolist } from '@/api/todolist_manager'
import { Message } from 'element-ui'

const state = {
   todolist:[]
}

const mutations = {
    SET_TODOLIST: (state, todolist) => {
        state.todolist = todolist
    },
}

const actions = {
    // 得知所有最新消息
    getTodolist({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getTodolist(query).then(response => {
                commit('SET_TODOLIST', response.data)
                resolve(response.data)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有待辦事項
    insert_update_todolist({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_todolist(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
