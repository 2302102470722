import { asyncRoutes, constantRoutes } from '@/router'
import router, { resetRouter } from '../../router'
import { getUserMenu, userRoutes } from '@/api/user'
import Layout from '@/layout'
import { _ } from 'core-js'
/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: []
  // addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    // state.addRoutes = routes
    // console.log("routes", routes.length)
    // state.routes = constantRoutes.concat(routes).concat({ path: '*', redirect: '/404', hidden: true })
    // console.log("arr_before", constantRoutes)
    state.routes = constantRoutes.concat(routes).concat({ path: '*', redirect: '/404', hidden: true })
    // router.addRoutes(state.routes)
    console.log("arr_after", state.routes)
  }
}

const actions = {
  // 產生預設權限
  async generateRoutes({ commit }, roles) {
    return new Promise((resolve, reject) => {
    getUserMenu().then(response => {
        // console.log(response.data.list);
        // console.log(Layout);
        // console.log(_.uniqBy(response.data.list, 'premission_father_id'))
        let arr = []
        _.uniqBy(response.data.list, 'premission_father_id').forEach((items) => {
          // console.log("items",items)
          let obj = {}
          if (items.premission_father_id==null){
            if (items.premission_meta_affix == "false") {
              items.premission_meta_affix = false
            }
            if (items.premission_meta_affix == "true") {
              items.premission_meta_affix = true
            }
            // console.log(_.filter(response.data.list, {'premission_father_id':null}))
            _.filter(response.data.list, { 'premission_father_id': null }).forEach((items) => {
              // console.log(items.premission_route)
              let componenturl = items.premission_name
              obj.path = items.premission_route,
              obj.component = Layout,
              obj.redirect = 'noRedirect',
              obj.children = []
              obj.children.push({
                path: 'index',
                component: (resolve) => require([`@/views/${componenturl}/index.vue`], resolve),
                name: items.premission_name,
                meta: {
                  title: items.premission_meta_title,
                  icon: items.premission_meta_icon,
                  affix: items.premission_meta_affix
                }
              })
              arr.push(obj)
              // console.log(arr)
            })
            // arr.push(obj)
          }
          else if (items.premission_father_id !=null){
            // console.log(items.premission_father_id)
            if (items.premission_meta_affix == "false") {
              items.premission_meta_affix = false
            }
            if (items.premission_meta_affix == "true") {
              items.premission_meta_affix = true
            }
            //父層
            obj.path = items.premission_father_path,
            obj.component = Layout,
            obj.redirect = 'noRedirect',
              // obj.redirect = items.premission_father_redirect,
            obj.name = 'Table',
            obj.meta = { 
                title: items.premission_father_name,
                icon: items.premission_father_meta_icon
            },
            obj.children = []
            // console.log(_.filter(response.data.list, items.premission_father_id))
            _.filter(response.data.list, { 'premission_father_id': items.premission_father_id }).forEach((items)=>{
              let componentfatherurl = items.premission_father_path
              let componenturl_many = items.premission_name
              if (items.premission_meta_affix == "false") {
                items.premission_meta_affix = false
              }
              if (items.premission_meta_affix == "true") {
                items.premission_meta_affix = true
              }
              obj.children.push({
                path: items.premission_name,
                // component: () => import(url),
                component: (resolve) => require([`@/views${componentfatherurl}/${componenturl_many}.vue`], resolve),
                name: items.premission_name,
                meta: {
                  title: items.premission_meta_title,
                  icon: items.premission_meta_icon,
                  affix: items.premission_meta_affix
                }
              })
            })
            arr.push(obj)
            // console.log("1",arr)
            // commit('SET_ROUTES', arr)
          }
        })
        // console.log("2",arr)
        commit('SET_ROUTES', arr)
        resolve(arr)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
  // 產生使用者合作夥伴權限
  async userRoutes({ commit }, payload) {
    return new Promise((resolve, reject) => {
    userRoutes(payload).then(response => {
      console.log(response)
      // console.log(Layout);
      // console.log(_.uniqBy(response.data.list, 'premission_father_id'))
      let arr = []
      if (response.data !=[]){
        _.uniqBy(response.data, 'premission_father_id').forEach((items) => {
          // console.log("items",items)
          let obj = {}
          if (items.premission_father_id == null) {
            if (items.premission_meta_affix == "false") {
              items.premission_meta_affix = false
            }
            if (items.premission_meta_affix == "true") {
              items.premission_meta_affix = true
            }
            // console.log(_.filter(response.data.list, {'premission_father_id':null}))
            _.filter(response.data, { 'premission_father_id': null }).forEach((items) => {
              console.log(items.premission_route)
              let componenturl = items.premission_name
              obj.path = items.premission_route,
                obj.component = Layout,
                obj.redirect = 'noRedirect',
                obj.children = []
              obj.children.push({
                path: 'index',
                component: (resolve) => require([`@/views/${componenturl}/index.vue`], resolve),
                name: items.premission_name,
                meta: {
                  title: items.premission_meta_title,
                  icon: items.premission_meta_icon,
                  affix: items.premission_meta_affix
                }
              })
              arr.push(obj)
              // console.log(arr)
            })
            // arr.push(obj)
          }
          else if (items.premission_father_id != null) {
            console.log(items.premission_father_id)
            if (items.premission_meta_affix == "false") {
              items.premission_meta_affix = false
            }
            if (items.premission_meta_affix == "true") {
              items.premission_meta_affix = true
            }
            //父層
            obj.path = items.premission_father_path,
              obj.component = Layout,
              obj.redirect = 'noRedirect',
              // obj.redirect = items.premission_father_redirect,
              obj.name = 'Table',
              obj.meta = {
                title: items.premission_father_name,
                icon: items.premission_father_meta_icon
              },
              obj.children = []
            // console.log(_.filter(response.data.list, items.premission_father_id))
            _.filter(response.data, { 'premission_father_id': items.premission_father_id }).forEach((items) => {
              let componentfatherurl = items.premission_father_path
              let componenturl_many = items.premission_name
              if (items.premission_meta_affix == "false") {
                items.premission_meta_affix = false
              }
              if (items.premission_meta_affix == "true") {
                items.premission_meta_affix = true
              }
              obj.children.push({
                path: items.premission_name,
                // component: () => import(url),
                component: (resolve) => require([`@/views${componentfatherurl}/${componenturl_many}.vue`], resolve),
                name: items.premission_name,
                meta: {
                  title: items.premission_meta_title,
                  icon: items.premission_meta_icon,
                  affix: items.premission_meta_affix
                }
              })
            })
            arr.push(obj)
            // console.log("1",arr)
            // commit('SET_ROUTES', arr)
          }
        })
      }
      // console.log("2",arr)
      commit('SET_ROUTES', arr)
      resolve(arr)
    }).catch(error => {
      console.log(error)
      reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
