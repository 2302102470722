import { render, staticRenderFns } from "./UnfixedThead.vue?vue&type=template&id=216e2818&"
import script from "./UnfixedThead.vue?vue&type=script&lang=js&"
export * from "./UnfixedThead.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/www/wwwroot/noblecare-backend.mindful-birthing.com.tw/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('216e2818')) {
      api.createRecord('216e2818', component.options)
    } else {
      api.reload('216e2818', component.options)
    }
    module.hot.accept("./UnfixedThead.vue?vue&type=template&id=216e2818&", function () {
      api.rerender('216e2818', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/table/dynamic-table/components/UnfixedThead.vue"
export default component.exports