import request from '@/utils/request'

export function getMainLinePackage(query) {
    console.log("getMainLinePackage",typeof(query))
    // console.log("get_question", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/main_line_package/' + query,
            method: 'get',
        })
    }
    else{
        return request({
            url: '/api/main_line_package/' + query,
            method: 'get',
        })
    }
}
export function insert_update_main_line_package(data) {
    // console.log(data);
    return request({
        url: '/api/main_line_package',
        method: 'post',
        data
    })
}

export function getSubLinePackage(query) {
    console.log("getSubLinePackage", typeof (query))
    // console.log("get_question", query)
    if (typeof (query) == "number") {
        return request({
            url: '/api/sub_line_package/' + query,
            method: 'get',
        })
    }
    else {
        return request({
            url: '/api/sub_line_package/' + query,
            method: 'get',
        })
    }
}
export function insert_update_sub_line_package(data) {
    // console.log(data);
    return request({
        url: '/api/sub_line_package',
        method: 'post',
        data
    })
}