import { getAdmin, insert_update_admin, getAdminRoles, insert_update_admin_role, getCustomeLogin } from '@/api/admin_manager'
import { Message } from 'element-ui'

const state = {
    adminlist:[],
    roleslist:[],
    custome_token:''
}

const mutations = {
    SET_ADMIN: (state, adminlist) => {
        state.adminlist = adminlist
    },
    SET_ROLES: (state, roleslist) => {
        state.roleslist = roleslist
    },
    SET_CUSTOME_TOKEN: (state, custome_token) => {
        state.custome_token = custome_token
    },
}

const actions = {
    // 得知所有管理模組
    getAdmin({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getAdmin(query).then(response => {
                commit('SET_ADMIN', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有管理模組
    insert_update_admin({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_admin(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success==true?'success':'error',
                    duration: 3500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知所有管理模組角色
    getAdminRoles({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getAdminRoles(query).then(response => {
                commit('SET_ROLES', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有管理模組角色
    insert_update_admin_role({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_admin_role(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success == false ? 'error' : 'success',
                    duration: 3500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知使用者驗證token
    getCustomeLogin({ commit }, query) {
        return new Promise((resolve, reject) => {
            getCustomeLogin(query).then(response => {
                console.log("get custome token", response.token)
                commit('SET_CUSTOME_TOKEN', response.token)
                resolve(response.token)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
