import request from '@/utils/request'

// 得知合作夥伴學員模組
export function getStudent(query) {
  console.log('get_member_list', typeof (query))
  // console.log("get_member_list", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/hs/member_list/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/hs/member_list/' + query,
      method: 'get'
    })
  }
}
// 驗證權限及授權狀態
export function check_class_status(data) {
  // console.log(data);
  return request({
    url: '/api/hs/member_info',
    method: 'post',
    data
  })
}
// 得知合作夥伴單一一位學員模組-會員基本資料
export function getSingleStudent(query) {
  console.log('get_member_info', typeof (query))
  // console.log("get_member_list", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/hs/member_info/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/hs/member_info/' + query,
      method: 'get'
    })
  }
}
// 更新合作夥伴單一一位學員模組-會員基本資料
export function updateSingleStudent(data) {
  // console.log(data);
  return request({
    url: '/api/hs/member_info',
    method: 'post',
    data
  })
}

// 得知合作夥伴單一一位學員模組-量測資料
export function getMeatureStudent(query) {
  console.log('get_meature_info', typeof (query))
  // console.log("get_member_list", query)
  if (typeof (query) === 'number') {
    return request({
      url: '/api/hs/record_get/' + query,
      method: 'get'
    })
  } else {
    return request({
      url: '/api/hs/record_get/' + query,
      method: 'get'
    })
  }
}

// 更新合作夥伴單一一位學員模組-量測資料
export function updateMeatureStudent(data) {
  // console.log(data);
  return request({
    url: '/api/hs/record_update',
    method: 'post',
    data
  })
}

// 更新合作夥伴單一一位學員模組-匯入多筆量測紀錄（單人）
export function updateBatchMeatureSingleStudent(data) {
  // console.log(data);
  return request({
    url: '/api/hs/member_record_batch_upload',
    method: 'post',
    data
  })
}

// 得知合作夥伴單一一位學員模組- 使用前後照片
export function getPhotoStudent(data) {
  return request({
    url: '/api/hs/image_get',
    method: 'post',
    data
  })
}

// 得知合作夥伴單一一位學員模組- 使用前後照片上傳
export function uploadPhotoStudent(data) {
  return request({
    url: '/api/hs/image_update',
    method: 'post',
    data
  })
}

// 得知合作夥伴單一一位學員模組- 使用前後照片分類列表
export function getPhotoList(query) {
  console.log('get_class_note_list', typeof (query))
  // console.log("get_member_list", query)
  return request({
    url: '/api/hs/class_note_list/' + query,
    method: 'get'
  })
}
// 得知合作夥伴單一一位學員模組- 指數統計
export function getSingleStudentRecord(data) {
  return request({
    url: '/api/hs/member_record_get',
    method: 'post',
    data
  })
}

// 得知合作夥伴單一一位學員模組- 圖表統計
export function getSingleStudentChartRecord(data) {
  return request({
    url: '/api/hs/specify_member_record_get',
    method: 'post',
    data
  })
}
