import request from '@/utils/request'

export function getHotnews(query) {
    console.log("get_hotnews",typeof(query))
    // console.log("get_question", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/hotnews/' + query,
            method: 'get',
        })
    }
    else{
        return request({
            url: '/api/hotnews/' + query,
            method: 'get',
        })
    }
}
export function getHotnewsCategory(page) {
    return request({
        url: '/api/hotnews_category/' + page,
        method: 'get',
    })
}
export function insert_update_hotnews(data) {
  // console.log(data);
    return request({
        url: '/api/hotnews',
        method: 'post',
        data
    })
}
export function insert_update_hotnews_category(data) {
    // console.log(data);
    return request({
        url: '/api/hotnews_category',
        method: 'post',
        data
    })
}