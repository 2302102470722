import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/api/login',
    method: 'post',
    data
  })
}

export function loginotp(data) {
  return request({
    url: '/api/loginotp',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/api/profile',
    method: 'get'
  })
}

export function getUserMenu(data=null) {
  // if (data['premission_father_id'] != null || data['premission_name'] != null || data['premission_meta_affix'] != null || data['premission_meta_title'] != null){
  //   console.log('api/premission/1?premission_father_id=' + data['premission_father_id'] +
  //     '&premission_name=' + data['premission_name'] + '&premission_meta_affix=' + data['premission_meta_affix'] +
  //     '&premission_meta_title=' + data['premission_meta_title']);
  //   return request({
  //     url: 'api/premission/1?premission_father_id=' + data['premission_father_id']+
  //       '&premission_name=' + data['premission_name'] + '&premission_meta_affix=' + data['premission_meta_affix'] +
  //       '&premission_meta_title=' + data['premission_meta_title'],
  //     method: 'get'
  //   })
  // console.log(data,'api/premission/1?premission_father_id=' + data +
  //   '&premission_name=' + "" + '&premission_meta_affix=' + "" +
  //   '&premission_meta_title=' + "")
  if(data){
    return request({
      url: 'api/premission/1?premission_father_id='+data+
        '&premission_name=' + "" + '&premission_meta_affix=' + "" +
        '&premission_meta_title=' + "",
      method: 'get'
    })
  }
  // }
  else{
    return request({
      url: 'api/premission/1',
      method: 'get'
    })
  }
}

export function userRoutes(data) {
  // if (data['premission_father_id'] != null || data['premission_name'] != null || data['premission_meta_affix'] != null || data['premission_meta_title'] != null){
  //   console.log('api/premission/1?premission_father_id=' + data['premission_father_id'] +
  //     '&premission_name=' + data['premission_name'] + '&premission_meta_affix=' + data['premission_meta_affix'] +
  //     '&premission_meta_title=' + data['premission_meta_title']);
  //   return request({
  //     url: 'api/premission/1?premission_father_id=' + data['premission_father_id']+
  //       '&premission_name=' + data['premission_name'] + '&premission_meta_affix=' + data['premission_meta_affix'] +
  //       '&premission_meta_title=' + data['premission_meta_title'],
  //     method: 'get'
  //   })
  // console.log(data,'api/premission/1?premission_father_id=' + data +
  //   '&premission_name=' + "" + '&premission_meta_affix=' + "" +
  //   '&premission_meta_title=' + "")
  if (data) {
    return request({
      url: 'api/partner_roles_premission/all?partner_id=' + data['partner_id'] +
        '&roles_id=' + data['roles_id'],
      method: 'get'
    })
  }
}


export function logout() {
  return request({
    url: '/api/logout',
    method: 'post'
  })
}
