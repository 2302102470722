import request from '@/utils/request'

export function getPushchannel(query) {
    console.log("get_push_channel",typeof(query))
    // console.log("get_admin", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/push_channel/' + query,
            method: 'get',
        })
    }
    else{
        return request({
            url: '/api/push_channel/' + query,
            method: 'get',
        })
    }
}
export function insert_update_push_channel(data) {
  // console.log(data);
    return request({
        url: '/api/push_channel',
        method: 'post',
        data
    })
}
export function getAdminRoles(query) {
    console.log("get_admin_roles", typeof (query))
    // console.log("get_admin_roles", query)
    if (typeof (query) == "number") {
        return request({
            url: '/api/roles/' + query,
            method: 'get',
        })
    }
    else {
        return request({
            url: '/api/roles/' + '1' + '?' + query,
            method: 'get',
        })
    }
}
//回復密碼設定
export function reset_password_admin(data) {
    // console.log(data);
    return request({
        url: '/api/admin/reset_password',
        method: 'post',
        data
    })
}

//顯示所有line_notify_group模組
export function getPushchannelGroup(query) {
    console.log("get_push_channel_group", typeof (query))
    // console.log("get_admin", query)
    if (typeof (query) == "number") {
        return request({
            url: '/api/show_notify_group/' + query,
            method: 'get',
        })
    }
    else {
        return request({
            url: '/api/show_notify_group/' + query,
            method: 'get',
        })
    }
}

// 綁定line notify group
export function BindLineaccesstokengroup(data) {
    // console.log(data);
    return request({
        url: '/api/bind_notify_group',
        method: 'post',
        data
    })
}
// 解除綁定line notify group
export function NotBindLineaccesstokengroup(data) {
    // console.log(data);
    return request({
        url: '/api/revoke_notify_group',
        method: 'post',
        data
    })
}

// 更新line notify group
export function UpdateNotifyGroup(data) {
    // console.log(data);
    return request({
        url: '/api/update_notify_group',
        method: 'post',
        data
    })
}