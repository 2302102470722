import { getQuestion, getQuestionCategory, insert_update_question, insert_update_question_category } from '@/api/question_manager'
import { Message } from 'element-ui'

const state = {
   questionlist:[],
   questioncategorylist: []
}

const mutations = {
    SET_QUESTION: (state, questionlist) => {
        state.questionlist = questionlist
    },
    SET_QUESTION_CATEGORY: (state, questioncategorylist) => {
        state.questioncategorylist = questioncategorylist
    }
}

const actions = {
    // 得知所有常見問題
    getQuestion({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getQuestion(query).then(response => {
                commit('SET_QUESTION', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 得知所有常見問題分類
    getQuestionCategory({ commit }, page) {
        return new Promise((resolve, reject) => {
            getQuestionCategory(page).then(response => {
                // console.log("test", response)
                commit('SET_QUESTION_CATEGORY', response.data.list)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有常見問題
    insert_update_question({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_question(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有常見問題分類
    insert_update_question_category({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_question_category(data).then(response => {
                Message({
                    message: response.message,
                    type: 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    }

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
