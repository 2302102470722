<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="main-article" element-loading-text="正在產生收據pdf">
    <!-- 留存收據(管理端) start -->
    <!-- {{reception_shop_List[0]}} -->
    <div>
      <div class="article__heading">
        <div class="article__heading__title" style="margin:10px 0;font-size:15px;text-align:center;">
          <!-- {{ article.title }} -->
          {{unitList[0]['reserve_system_unit_name']}}
        </div>
        <div class="article__heading__title" style="margin:10px 0;font-size:15px;text-align:center;">
          <!-- {{ article.title }} -->
          收據
        </div>
        <div class="article__heading__title" style="color:red;font-size:15px;text-align:right;">
          第一聯 單位留存聯
        </div>
        <div class="article__heading__title" style="margin:10px 0;font-size:15px;text-align:right;">
          <!-- {{ article.title }} -->
          單據序號：{{reception_shop_List[0]['reserve_system_reception_id']}}
        </div>
      </div>
      <div class="user-profile">
        <table style="margin:0px 10px;">
          <tbody>
            <tr>
              <td width="200">
                姓名：{{reception_shop_List[0]['reserve_system_member_username']}}
              </td>
              <td width="200">
                出生年月日：{{reception_shop_List[0]['reserve_system_member_birthday']}}
              </td>
              <td colspan="3" width="348">
                訂單日期：
                  {{reception_shop_List[0]['reserve_system_reserve_date']}}
                
              </td>
            </tr>
            <!-- <tr>
              <td width="348">
                <p>費用期間：
                  {{reception_shop_List[0]['reserve_system_shop_basic_schedule_start_time']}} 
                  ~
                  {{reception_shop_List[0]['reserve_system_shop_basic_schedule_end_time']}}
                </p>
              </td>
              <td colspan="3" width="348">
                <p>&nbsp;</p>
              </td>
            </tr> -->
            <tr>
              <td colspan="3" width="538" align="center">
                費用項目
              </td>
              <td width="158" align="center">
                金額
              </td>
            </tr>
            <tr v-for="(item,index) in reception_shop_List[0]['get_reception_detail']" :key="index">
              <td colspan="3" width="538">
                {{item.reserve_system_reception_item_name}}
              </td>
              <td width="158" align="center">
                {{item.reserve_system_reception_item_price}}
              </td>
            </tr>
            <tr>
              <td colspan="3" width="538">
                <strong><strong>應繳金額</strong></strong>
              </td>
              <td width="158" align="center">{{reception_shop_List[0]['reserve_system_amount_payable']}}</td>
            </tr>
            <tr>
              <td colspan="3" width="538">
                <strong><strong>實繳金額</strong></strong>
              </td>
              <td width="158" align="center">
                {{reception_shop_List[0]['reserve_system_amount_practice']}}
              </td>
            </tr>
            <tr>
              <td colspan="3" width="538">
                <strong><strong>尚欠金額</strong></strong>
              </td>
              <td width="158" align="center">
                {{reception_shop_List[0]['reserve_system_amount_owed']}}
              </td>
            </tr>
            <tr>
              <td colspan="2" width="350">
                <p>{{unitList[0]['reserve_system_unit_name']}}</p>
                <p>地址：{{unitList[0]['reserve_system_unit_tax']}}</p>
                <p>電話：{{unitList[0]['reserve_system_unit_phone']}}</p>
                <p>收訖章：</p>
              </td>
              <td colspan="2" width="347">
                <p>收款資訊：</p>
                <p v-show="reception_shop_List[0]['reserve_system_pay_people']==''">□現金&nbsp;&nbsp; 繳款人：_______________</p>
                <p v-show="reception_shop_List[0]['reserve_system_pay_people']!=''">□現金&nbsp;&nbsp; 繳款人：{{reception_shop_List[0]['reserve_system_pay_people']}}</p>
                <p v-show="reception_shop_List[0]['reserve_system_pay_people']==''">□匯款&nbsp;&nbsp; 繳款人：_______________</p>
                <p v-show="reception_shop_List[0]['reserve_system_pay_people']!=''">□匯款&nbsp;&nbsp; 繳款人：{{reception_shop_List[0]['reserve_system_pay_people']}}</p>
                <p>帳號資訊：_________________</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <el-row :gutter="20">
          <el-col :xs="12" :sm="12" :lg="12" :xl="12" style="margin:10px 0;color:red;">
            本憑證請妥為保管，遺失恕不補發
          </el-col>
          <el-col :xs="12" :sm="12" :lg="12" :xl="12" style="margin:10px 0;color:red;text-align:right;">
            第一聯 單位留存聯
          </el-col>
      </el-row> -->
      <div style="margin:10px;color:red;">
        本憑證請妥為保管，遺失恕不補發
      </div>
    </div>
    <br/>
    <br/>
    <!-- 留存收據(管理端) end -->
    <!-- 留存收據(客戶端) start -->
    <div style="margin:5px 0;">
      <div class="article__heading">
        <div class="article__heading__title" style="margin:1px 0;font-size:15px;text-align:center;">
          <!-- {{ article.title }} -->
          {{unitList[0]['reserve_system_unit_name']}}
        </div>
        <div class="article__heading__title" style="margin:10px 0;font-size:15px;text-align:center;">
          <!-- {{ article.title }} -->
          收據
        </div>
        <div class="article__heading__title" style="color:red;font-size:15px;text-align:right;">
          <!-- {{ article.title }} -->
          第二聯 客戶留存聯
        </div>
        <div class="article__heading__title" style="margin:10px 0;font-size:15px;text-align:right;">
          <!-- {{ article.title }} -->
          單據序號：{{reception_shop_List[0]['reserve_system_reception_id']}}
        </div>
      </div>
      <div class="user-profile">
        <table style="margin:0px 10px;">
          <tbody>
            <tr>
              <td width="200">
                姓名：{{reception_shop_List[0]['reserve_system_member_username']}}
              </td>
              <td width="200">
                出生年月日：{{reception_shop_List[0]['reserve_system_member_birthday']}}
              </td>
              <td colspan="3" width="348">
                訂單日期：
                {{reception_shop_List[0]['reserve_system_reserve_date']}}
                
              </td>
            </tr>
            <!-- <tr>
              <td width="348">
                <p>費用期間：
                  {{reception_shop_List[0]['reserve_system_shop_basic_schedule_start_time']}} 
                  ~
                  {{reception_shop_List[0]['reserve_system_shop_basic_schedule_end_time']}}
                </p>
              </td>
              <td colspan="3" width="348">
                <p>&nbsp;</p>
              </td>
            </tr> -->
            <tr>
              <td colspan="3" width="538" align="center">
                費用項目
              </td>
              <td width="158" align="center">
                金額
              </td>
            </tr>
            <tr v-for="(item,index) in reception_shop_List[0]['get_reception_detail']" :key="index">
              <td colspan="3" width="538">
                {{item.reserve_system_reception_item_name}}
              </td>
              <td width="158" align="center">
                {{item.reserve_system_reception_item_price}}
              </td>
            </tr>
            <tr>
              <td colspan="3" width="538">
                <strong><strong>應繳金額</strong></strong>
              </td>
              <td width="158" align="center">{{reception_shop_List[0]['reserve_system_amount_payable']}}</td>
            </tr>
            <tr>
              <td colspan="3" width="538">
                <strong><strong>實繳金額</strong></strong>
              </td>
              <td width="158" align="center">
                {{reception_shop_List[0]['reserve_system_amount_practice']}}
              </td>
            </tr>
            <tr>
              <td colspan="3" width="538">
                <strong><strong>尚欠金額</strong></strong>
              </td>
              <td width="158" align="center">
                {{reception_shop_List[0]['reserve_system_amount_owed']}}
              </td>
            </tr>
            <tr>
              <td colspan="2" width="350">
                <p>{{unitList[0]['reserve_system_unit_name']}}</p>
                <p>地址：{{unitList[0]['reserve_system_unit_tax']}}</p>
                <p>電話：{{unitList[0]['reserve_system_unit_phone']}}</p>
                <p>收訖章：</p>
              </td>
              <td colspan="2" width="347">
                <p>收款資訊：</p>
                <p v-show="reception_shop_List[0]['reserve_system_pay_people']==''">□現金&nbsp;&nbsp; 繳款人：_______________</p>
                <p v-show="reception_shop_List[0]['reserve_system_pay_people']!=''">□現金&nbsp;&nbsp; 繳款人：{{reception_shop_List[0]['reserve_system_pay_people']}}</p>
                <p v-show="reception_shop_List[0]['reserve_system_pay_people']==''">□匯款&nbsp;&nbsp; 繳款人：_______________</p>
                <p v-show="reception_shop_List[0]['reserve_system_pay_people']!=''">□匯款&nbsp;&nbsp; 繳款人：{{reception_shop_List[0]['reserve_system_pay_people']}}</p>
                <p>帳號資訊：_________________</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style="margin:10px;color:red;">
        本憑證請妥為保管，遺失恕不補發
      </div>
    </div>
    <!-- 留存收據(客戶端) end -->

  </div>
</template>
<style>
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
  border-spacing: 5px;
}
.grecaptcha-badge{ 
    visibility: hidden;
}
@media print {
    html, body {
        height: 99%;    
    }
}
</style>
<script>
import moment from 'moment'
import { getAppointReceptionShopList } from '@/api/reservationsystemreception'
import { getUnitList } from '@/api/reservationsystemunit'
export default {
  data() {
    return {
      fullscreenLoading: true,
      //報告產生日期
      report_date:'',
      search_reception_shop:'',
      search_unit:'',
      reception_shop_List:[],
      unitList:[]
    }
  },
  async mounted() {
    this.report_date = moment(new Date()).format('YYYY/MM/DD')
    console.log("this.$route.params.reserve_system_order_sn",this.$route.params.reserve_system_order_sn)
    this.search_reception_shop = 'partner_id='+this.$route.params.partner_id+'&reserve_system_order_sn='+this.$route.params.reserve_system_order_sn
    this.search_unit = 'partner_id='+this.$route.params.partner_id
    await this.getAllUnit(1,this.search_unit)
    await this.getAllReceptionShop(1, this.search_reception_shop)
    await this.fetchData()
  },
  methods: {
      fetchData() {
      import('./content.js').then(data => {
        // const { title } = data.default
        document.title = this.reception_shop_List[0]['reserve_system_member_username'] +'留存收據 '+
        "("+this.reception_shop_List[0]['reserve_system_reception_id']+")"
        this.article = data.default
        setTimeout(() => {
          this.fullscreenLoading = false
          this.$nextTick(() => {
            window.print()
          })
        }, 500)
      })
    },
    async getAllReceptionShop(page, reception_shop) {
      var payload = ''
      if (this.search_reception_shop == '') {
        payload = page
      }
      if (this.search_reception_shop != '') {
        payload += page + '?' + reception_shop
      }
      await getAppointReceptionShopList(payload).then(resp => {
        this.reception_shop_List = resp.data.list
      })
    },
    async getAllUnit(page, unit) {
      var payload = ''
      var _self = this
      if ( _self.search_unit == '') {
        payload = page
      }
      if ( _self.search_unit != '') {
        payload += page + '?' + unit
      }
      getUnitList(payload).then(resp => {
        _self.unitList = resp.data.list
      })
    },
  }
}
</script>