import { getDocument, insert_update_document, getDocumentPartner, insert_update_document_partner } from '@/api/document_manager'
import { Message } from 'element-ui'

const state = {
   documentlist:[],
   documentpartnerlist:[]
}

const mutations = {
    SET_DOCUMENT: (state, documentlist) => {
        state.documentlist = documentlist
    },
    SET_DOCUMENT_PARTNER: (state, documentpartnerlist) => {
        state.documentpartnerlist = documentpartnerlist
    },
}

const actions = {
    // 得知所有說明文件
    getDocument({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getDocument(query).then(response => {
                commit('SET_DOCUMENT', response.data)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有常見問題
    insert_update_document({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_document(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success == false ? 'error' : 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    //所有說明文件對應合作夥伴
    getDocumentPartner({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getDocumentPartner(query).then(response => {
                commit('SET_DOCUMENT_PARTNER', response.data)
                resolve(response.data.list)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有說明文件對應合作夥伴
    insert_update_document_partner({ commit }, data) {
        return new Promise((resolve, reject) => {
            insert_update_document_partner(data).then(response => {
                if (response.success == false){
                    Message({
                        message: response.data,
                        type: 'error',
                        duration: 7000
                    })
                }else{
                    Message({
                        message: response.message,
                        type: 'success',
                        duration: 2500
                    })
                }
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
