<template>
  <el-row :gutter="40" class="panel-group">
    <el-col :xs="12" :sm="8" :lg="8" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('shopping')">
        <div class="card-panel-icon-wrapper icon-shopping">
          <svg-icon icon-class="shopping" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            {{get_appoint_reserve_title}}
          </div>
          <count-to :start-val="0" :end-val="appoint_reserve" :duration="1500" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <!-- <el-col :xs="12" :sm="8" :lg="8" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('money')">
        <div class="card-panel-icon-wrapper icon-money">
          <svg-icon icon-class="money" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            {{get_appoint_earn_title}}
          </div>
          <count-to :start-val="0" :end-val="appoint_earn" :duration="1500" class="card-panel-num" />
        </div>
      </div>
    </el-col> -->
    <!-- <el-col :xs="24" :sm="8" :lg="8" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('user')">
        <div class="card-panel-icon-wrapper icon-user">
          <svg-icon icon-class="user" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            {{get_appoint_member_title}}
          </div>
          <count-to :start-val="0" :end-val="appoint_member" :duration="1500" class="card-panel-num" />
        </div>
      </div>
    </el-col> -->
    <el-col :xs="24" :sm="8" :lg="8" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('login')">
        <div class="card-panel-icon-wrapper icon-people">
          <svg-icon icon-class="people" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            {{get_login_admin_title}}
          </div>
          <count-to :start-val="0" :end-val="login_admin" :duration="1500" class="card-panel-num" />
        </div>
      </div>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="8" class="card-panel-col">
      <div class="card-panel" @click="handleSetLineChartData('log')">
        <div class="card-panel-icon-wrapper icon-edit">
          <svg-icon icon-class="edit" class-name="card-panel-icon" />
        </div>
        <div class="card-panel-description">
          <div class="card-panel-text">
            {{get_log_title}}
          </div>
          <count-to :start-val="0" :end-val="edit_log" :duration="1500" class="card-panel-num" />
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CountTo from 'vue-count-to'

export default {
  components: {
    CountTo
  },
  props:{
      appoint_reserve:{
          type: Number,
          require:true
      },
      appoint_earn:{
          type: Number,
          require:true
      },
      appoint_member:{
          type: Number,
          require:true
      },
      login_admin:{
          type: Number,
          require:true
      },
      edit_log:{
          type: Number,
          require:true
      },
      select_range_time: {
          type: Number,
          require:true
      }
  },
    watch:{
    "select_range_time":{
        handler: function(val, oldVal) {
          //日
          if(val==0){
            this.get_appoint_reserve_title = '日預約'
            this.get_appoint_earn_title = '日盈餘'
            this.get_appoint_member_title = '日會員'
            this.get_login_admin_title = '日登入（次數）'
            this.get_log_title = '日修改（次數）'
          }
          //週
          if(val==1){
            this.get_appoint_reserve_title = '週預約'
            this.get_appoint_earn_title = '週盈餘'
            this.get_appoint_member_title = '週會員'
            this.get_login_admin_title = '週登入（次數）'
            this.get_log_title = '週修改（次數）'
          }
          //月
          if(val==2){
            this.get_appoint_reserve_title = '月預約'
            this.get_appoint_earn_title = '月盈餘'
            this.get_appoint_member_title = '月會員'
            this.get_login_admin_title = '月登入（次數）'
            this.get_log_title = '月修改（次數）'
          }
          //年
          if(val==3){
            this.get_appoint_reserve_title = '年預約'
            this.get_appoint_earn_title = '年盈餘'
            this.get_appoint_member_title = '年會員'
            this.get_login_admin_title = '年登入（次數）'
            this.get_log_title = '年修改（次數）'
          }
        },
      immediate: true
    }
  },
  data() {
    return {
      get_appoint_reserve_title:'',
      get_appoint_earn_title:'',
      get_appoint_member_title:'',
      get_login_admin_title:'',
      get_log_title:''
    }
  },
  methods: {
    handleSetLineChartData(type) {
      console.log(type)
      this.$emit('handleSetLineChartData', type)
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-group {
  margin-top: 18px;

  .card-panel-col {
    margin-bottom: 32px;
  }

  .card-panel {
    height: 108px;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    color: #666;
    background: #fff;
    box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
    border-color: rgba(0, 0, 0, .05);

    &:hover {
      .card-panel-icon-wrapper {
        color: #fff;
      }

      .icon-shopping,.icon-people {
        background: #40c9c6;
      }

      .icon-message {
        background: #36a3f7;
      }

      .icon-money {
        background: #f4516c;
      }

      .icon-user,.icon-edit {
        background: #36a3f7;
      }
    }

    .icon-shopping,.icon-people {
      color: #40c9c6;
    }

    .icon-message {
      color: #36a3f7;
    }

    .icon-money {
      color: #f4516c;
    }

    .icon-user,.icon-edit {
      color: #36a3f7;
    }

    .card-panel-icon-wrapper {
      float: left;
      margin: 14px 0 0 14px;
      padding: 16px;
      transition: all 0.38s ease-out;
      border-radius: 6px;
    }

    .card-panel-icon {
      float: left;
      font-size: 48px;
    }

    .card-panel-description {
      float: right;
      font-weight: bold;
      margin: 26px;
      margin-left: 0px;

      .card-panel-text {
        line-height: 18px;
        color: rgba(0, 0, 0, 0.45);
        font-size: 16px;
        margin-bottom: 12px;
      }

      .card-panel-num {
        font-size: 20px;
      }
    }
  }
}

@media (max-width:550px) {
  // .card-panel-description {
  //   display: none;
  // }
  // .card-panel-icon-wrapper{
  //   display: none;
  // }
  // .card-panel-icon-wrapper {
  //   float: none !important;
  //   width: 100%;
  //   height: 100%;
  //   margin: 0 !important;

  //   .svg-icon {
  //     display: block;
  //     margin: 14px auto !important;
  //     float: none !important;
  //   }
  // }
}
</style>
