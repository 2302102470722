import { getContract, insert_update_contract } from '@/api/contract'
import { Message } from 'element-ui'

const state = {
    contractlist:[]
}

const mutations = {
    SET_CONTRACT: (state, contractlist) => {
        state.contractlist = contractlist
    }
}

const actions = {
    // 得知所有合約模組
    getContract({ commit }, query) {
        return new Promise((resolve, reject) => {
            console.log(query)
            getContract(query).then(response => {
                commit('SET_CONTRACT', response.data)
                resolve(response.data)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    },
    // 新增及修改所有合約模組
    insert_update_contract({ commit }, data){
        return new Promise((resolve, reject) => {
            insert_update_contract(data).then(response => {
                Message({
                    message: response.message,
                    type: response.success == false ? 'error' : 'success',
                    duration: 1500
                })
                resolve(response)
            }).catch(error => {
                console.log("error", error)
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
