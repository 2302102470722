<template>
  <el-form>
    <!-- {{user}} -->
    <el-form-item label="合作夥伴名稱">
      <el-tag type='danger' size="medium" effect="plain" v-if="user.partner_name==null">無</el-tag>
      <el-tag type='success' size="medium" effect="plain" v-else>{{user.partner_name}}</el-tag>
    </el-form-item>
    <el-form-item label="個人頭貼">
      <pan-thumb :image="avatar" />
      <div style="margin:50px 0;">
      <el-button type="primary" icon="el-icon-upload" style="position: absolute;bottom: 5px;margin-left: 7%;" @click="imagecropperavatarShow=true">
        個人頭貼
      </el-button>
      <el-tag type='danger' effect="plain" style="position: absolute;bottom: -30px;margin-left: 6%;">請注意大小最好為正方形</el-tag>
      </div>
      <image-cropper
        v-show="imagecropperavatarShow"
        :key="imagecropperavatarKey"
        :width="150"
        :height="150"
        :url="upload_avatar_path"
        lang-type="zh-tw"
        field="avatar"
        @close="updateavatarclose"
        @crop-upload-success="updateavatarlogo"
      />
    </el-form-item>
    <el-form-item label="姓名">
      <el-input v-model.trim="user.name" />
    </el-form-item>
    <el-form-item label="電話">
      <el-input v-model.trim="user.phone" />
    </el-form-item>
    <el-form-item label="信箱">
      <el-input v-model.trim="user.email" />
    </el-form-item>
    <el-form-item label="簡介">
      <el-input v-model.trim="user.introduction" />
    </el-form-item>
    <el-form-item label="特休">
      {{user.special_day}} 天
    </el-form-item>
    <el-form-item label="角色">
      <el-select
          v-model="user.roles"
          name="roles"
          filterable
          allow-create
          default-first-option
          clearable
          placeholder="選擇角色"
          ref="roles"
          disabled
          >
          <el-option
          v-for="item in adminroleslist"
          :key="item.roles_id"
          :label="item.roles_name"
          :value="item.roles_id">
          </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="使用者狀態">
      <el-tag type='danger' size="medium" effect="plain" v-if="user.status==0">停權</el-tag>
      <el-tag type='success' size="medium" effect="plain" v-if="user.status==1">啟用</el-tag>
    </el-form-item>
    <el-form-item label="二次驗證開關" prop="validator_mode_status">
        <el-radio-group v-model="user.validator_mode_status" 
        ref="validator_mode_status" name="validator_mode_status">
            <el-radio-button label=0 border>關閉</el-radio-button>
            <el-radio-button label=1 border>開啟</el-radio-button>                     
        </el-radio-group>
    </el-form-item>
    <el-form-item label="二次驗證模式" prop="validator_mode">
        <el-radio-group v-model="user.validator_mode" 
        ref="validator_mode" name="validator_mode">
            <el-radio-button label=0 border v-show="user.validator_mode_status==0">無</el-radio-button>
            <el-radio-button label=1 border v-show="user.validator_mode_status==1">信箱</el-radio-button>
            <el-radio-button label=2 border v-show="user.validator_mode_status==1">line notify</el-radio-button>                        
        </el-radio-group>
    </el-form-item>
    <!-- {{push_channel_token}} -->
    <el-form-item label="line通知狀態" v-show="user.validator_mode_status==1&&user.validator_mode==2">
      <el-tag type='primary' size="medium" effect="plain" @click="chosen_notify_chat" v-if="push_channel_token==''">開啟綁定</el-tag>
      <el-tag type='danger' size="medium" effect="plain" @click="confirm_revoke_notify_chat" v-else>關閉綁定</el-tag>
    </el-form-item>
    <el-form-item label="line通知token" v-show="push_channel_token!=''&&user.validator_mode_status==1&&user.validator_mode==2">
      {{push_channel_token}}
    </el-form-item>
    <!-- v-if="user.partner_id!=null" -->
    <!-- {{partner_avatar}} -->
    <el-form-item label="設定合作夥伴logo預覽（適用後端上傳）" v-if="user.roles==1&&user.partner_id!=null">
      <pan-thumb :image="partner_avatar" />
      <div style="margin:50px 0;">
      <el-button type="primary" icon="el-icon-upload" style="position: absolute;bottom: 15px;margin-left: 40px;" @click="imagecropperpartnerShow=true">
        設定合作夥伴logo（適用後端上傳）
      </el-button>
      </div>
      <image-cropper
        v-show="imagecropperpartnerShow"
        :key="imagecropperpartnerKey"
        :width="200"
        :height="200"
        :url="upload_path"
        lang-type="zh-tw"
        field="partner_avatar"
        @close="updatepartnerclose"
        @crop-upload-success="updatepartnerlogo"
      />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="updateaccount">更新</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'
import ImageCropper from '@/components/ImageCropper'
import PanThumb from '@/components/PanThumb'
import { BindLineaccesstoken,RevokeLineaccesstoken }from '@/api/admin_manager'
export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: '',
          phone: '',
          email:'',
          introduction: '',
          roles: '',
          partner_id: '',
          partner_name: '',
          id: '',
          push_channel_token: '',
          special_day:0,
          validator_mode:null,
          validator_mode_status:null
        }
      }
    }
  },
  data() {
    return {
      // 合作夥伴logo上傳dialog顯示狀態
      imagecropperpartnerShow: false,
      //個人頭貼上傳dialog顯示狀態
      imagecropperavatarShow:false,
      // 合作夥伴logo上傳key
      imagecropperpartnerKey: 0,
      //個人頭貼上傳key
      imagecropperavatarKey: 0,
      //合作夥伴logo網址
      partner_avatar: '',
      //個人頭貼logo網址
      avatar:'',
     // 圖片上傳網址位置（合作夥伴logo）
      upload_path: '',
     // 圖片上傳網址位置(頭貼)
      upload_avatar_path: '',
    }
  },
  components: { ImageCropper, PanThumb },
  computed: {
    ...mapGetters([
      'adminroleslist',
      'partnerlist',
      'push_channel_token'
    ])
  },
  watch:{
    //選擇驗證開關
    "user.validator_mode_status":{
    handler: function(val, oldVal) {
      if(val==0){
        this.user.validator_mode = 0
      }
      if(val==1){
        if(this.user.validator_mode==1){
          this.user.validator_mode = 1
        }
        if(this.user.validator_mode==2){
          this.user.validator_mode = 2
        }
        //預設都為1
        else{
          this.user.validator_mode = 1
        }
      }
    },
    immediate: true
    },
  },
  async mounted() {
    await this.$store.dispatch('admin/getAdminRoles',1)
    await this.$store.dispatch('partner/getPartner',1)
    await this.$store.dispatch('user/getInfo').then((resp)=>{
      //合作夥伴logo
      if(resp.partner_avatar!=null) {
        this.partner_avatar = process.env.VUE_APP_BASE_API_UPLOAD + resp.partner_avatar
      }
      //個人頭貼(有頭貼放實際頭貼)
      if(resp.avatar!=null) {
        this.avatar = process.env.VUE_APP_BASE_API_UPLOAD + resp.avatar
      }
      //沒有實際頭貼放預設
      if(resp.avatar==null) {
        this.avatar = 'https://wpimg.wallstcn.com/f778738c-e4f8-4870-b634-56703b4acafe.gif?imageView2/1/w/80/h/80'
      }
    })
    // 圖片上傳網址位置（合作夥伴logo）
    this.upload_path = process.env.VUE_APP_BASE_API_TRANSFER + '/api/partner_logo'
    // 圖片上傳網址位置(頭貼)
    this.upload_avatar_path = process.env.VUE_APP_BASE_API_TRANSFER + '/api/avatar_logo'
    // 是否有line綁定參數
    if(this.$route.query.code) {
      // console.log(window.location.href.split('?')[0]);
      console.log(this.$route.query.code)
      this.getbindlinenotify(this.$route.query.code)
    }

  },
  methods: {
    updateaccount() {
        const AdminFormData = new FormData()
        AdminFormData.append('id',this.user.id)
        AdminFormData.append('partner_id',this.user.partner_id)
        AdminFormData.append('username',this.user.username)
        AdminFormData.append('name',this.user.name)
        AdminFormData.append('email',this.user.email)
        AdminFormData.append('phone',this.user.phone)
        AdminFormData.append('status',this.user.status)
        AdminFormData.append('introduction',this.user.introduction)
        AdminFormData.append('special_day',this.user.special_day)
        AdminFormData.append('roles',this.user.roles)
        AdminFormData.append('validator_mode',this.user.validator_mode)
        AdminFormData.append('validator_mode_status',this.user.validator_mode_status)
      this.$store.dispatch('admin/insert_update_admin', AdminFormData)
    },
    // 更新合作夥伴logo圖片
    async updatepartnerlogo(resData) {
      this.imagecropperpartnerShow = false
      this.imagecropperpartnerKey = this.imagecropperpartnerKey + 1
      console.log("before", resData.partner_avatar)
      // this.partner_avatar = ''
      this.partner_avatar = process.env.VUE_APP_BASE_API_UPLOAD + resData.partner_avatar
      console.log("after", this.partner_avatar)
      await this.$store.dispatch('user/getInfo')
      this.$emit('getUser')
    },
    // 關閉合作夥伴logo圖片
    updatepartnerclose() {
      this.imagecropperpartnerShow = false
    },
    //更新個人頭貼
    async updateavatarlogo(resData) {
      this.imagecropperavatarShow = false
      this.imagecropperavatarKey = this.imagecropperavatarKey + 1
      console.log("before", resData.avatar)
      // this.partner_avatar = ''
      this.avatar = process.env.VUE_APP_BASE_API_UPLOAD + resData.avatar
      console.log("after", this.avatar)
      await this.$store.dispatch('user/getInfo')
      this.$emit('getUser')
    },
    // 關閉個人頭貼
    updateavatarclose() {
      this.imagecropperavatarShow = false
    },
    chosen_notify_chat() {
      location.href =
        "https://notify-bot.line.me/oauth/authorize?" +
        "response_type=code" +
        "&client_id=WoKqpbFHGYjY3FcPykrId1" +
        "&redirect_uri="+window.location.href.split('?')[0] +
        "&scope=notify" +
        "&state=abcd";  // 自定義String防止CSRF攻擊
    },
    // 綁定line notify
    async getbindlinenotify(code) {
      await BindLineaccesstoken({'code': code}).then((resp)=>{
        this.$message({
          type: resp.success==true?'success':'error',
          message: resp.message
        })
        this.$router.push({'path': '/profile/index'})
      })
      await this.$store.dispatch('user/getInfo')
    },
    // 取消綁定
    confirm_revoke_notify_chat() {
      this.$confirm('登入將會無法發送OTP驗證碼', '是否更換綁定line通知', {
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(async() => {
          await RevokeLineaccesstoken().then((resp)=>{
            this.$message({
                type: resp.success==true?'success':'error',
                message: resp.message
            })
          })
          await this.$store.dispatch('user/getInfo')
        })
    }
  }
}
</script>
