import request from '@/utils/request'

// 得知目前合作夥伴角色
export function getPremissionRoles(query) {
    console.log("get_premission_roles",typeof(query))
    console.log("get_admin", query)
    if(typeof(query)=="number"){
        return request({
            url: '/api/all_partner_roles/' + query,
            method: 'get',
        })
    }
    else{
        return request({
            url: '/api/all_partner_roles/' + query,
            method: 'get',
        })
    }
}
// 得知目前合作夥伴權限
export function getPremission(query) {
    console.log("get_premission", typeof (query))
    // console.log("get_admin", query)
    if (typeof (query) == "number") {
        return request({
            url: '/api/partner_roles_premission/' + query,
            method: 'get',
        })
    }
    else {
        return request({
            url: '/api/partner_roles_premission/' + query,
            method: 'get',
        })
    }
}
// 新增與修改目前合作夥伴權限
export function insert_update_Premission(data) {
    // console.log(data);
    return request({
        url: '/api/partner_roles_premission',
        method: 'post',
        data
    })
}
// 得知使用者實際擁有權限
export function getAdminPremission(data){
    return request({
        url: '/api/check_admin_premission',
        method: 'post',
        data
    })
}